import React, { FC, Fragment, useContext, useEffect, useState } from 'react';

//  Componentts
import styled from 'styled-components/macro'; //babel macro allows classnames when debugging;
import { H1, H4, B1, Button } from '@formelio/react-components';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { onDevice } from '../constants/theme';

//  Assets
import scan from '../assets/images/scan_qr.png';

//  Context
import { AuthContext } from '..';
import { IrmaState, IrmaStatus, IrmaError } from '../types/irma-state';
import SelfSubmittingForm from './SelfSubmittingForm';

// IRMA
const IrmaCore = require('@privacybydesign/yivi-core');
const Web = require('@privacybydesign/yivi-web');
const Client = require('@privacybydesign/yivi-client');
require('@privacybydesign/yivi-css');

const qrSize = '180px';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 140px;

  ${onDevice.mobile} {
    padding: ${(p) => p.theme.size.space.m2};
    padding-top: 140px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledH1 = styled(H1)`
  ${onDevice.mobile} {
    font-size: ${(p) => p.theme.font.size.l};
    margin-bottom: ${(p) => p.theme.size.space.m};
  }
`;

const Box = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.size.space.m2};
  border: 1px solid ${(p) => p.theme.palette.grayDark};
  border-radius: 16px;
  position: relative;

  ${onDevice.mobile} {
    display: flex;
    justify-content: center;
    border: none;
  }
`;

const ScanContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.size.space.m2};

  // Delete default size of IRMA element
  .irma-web-form {
    max-width: unset;
    min-width: unset;
    min-height: unset;
    max-height: unset;
    background-color: white;
    border-radius: 0;
  }

  > img {
    width: 150px;
    height: 135px;
  }

  ${onDevice.mobile} {
    display: flex;
    justify-content: center;

    img {
      display: none;
    }
  }
`;
const QrContainer = styled.section`
  height: ${qrSize};
  width: ${qrSize};

  // Overrides irma-css configurations
  min-height: unset;
  max-width: ${qrSize};
  overflow: hidden;

  // built in IRMA styling
  .yivi-web-header {
    display: none;
  }

  // makes sure IRMA elements take full space
  div {
    margin: 0 !important;
  }

  /* This p is the text button */
  /* Examples: 'load QR' or 'try again' */
  p {
    margin: ${(p) => p.theme.size.space.m2} 0 0 0 !important;
  }

  // QR code
  .yivi-web-qr-canvas {
    width: ${qrSize} !important;
    height: ${qrSize} !important;
  }

  ${onDevice.mobile} {
    /* This p is the text button */
    /* Examples: 'load QR' or 'try again' */
    p {
      position: absolute;
      bottom: ${(p) => p.theme.size.space.m2};
      left: 0;
      right: 0;
      background-color: transparent;
      margin: 0 !important;
    }
  }
`;

const Content = styled.div`
  letter-spacing: ${(p) => p.theme.size.letterSpacing.xs};
  opacity: 0.8;

  h4 {
    font-size: ${(p) => p.theme.font.size.s};
    opacity: 1;
    letter-spacing: ${(p) => p.theme.size.letterSpacing.s};
  }

  li {
    font-size: ${(p) => p.theme.font.size.s};
    line-height: ${(p) => p.theme.size.lineHeight.m};
    margin-bottom: ${(p) => p.theme.size.space.s};
    opacity: 0.8;

    span {
      margin-right: 12px;
    }
  }

  li:last-child {
    margin: 0;
  }

  ${onDevice.mobile} {
    display: none;
  }
`;

const Text = styled(B1)`
  margin-top: ${(p) => p.theme.font.size.l};
  padding-bottom: ${(p) => p.theme.font.size.m};
  opacity: 0.9;
  text-align: right;
  letter-spacing: ${(p) => p.theme.size.letterSpacing.l};

  a {
    color: ${(p) => p.theme.color.text.light};
    font-weight: ${(p) => p.theme.font.weight.semiBold};
    text-decoration: underline;
    cursor: pointer;
  }

  ${onDevice.mobile} {
    display: none;
  }
`;

const TextMobile = styled(B1)`
  display: none;

  margin: 0;
  padding-bottom: ${(p) => p.theme.size.space.xl};

  opacity: 0.9;

  letter-spacing: ${(p) => p.theme.size.letterSpacing.l};

  text-align: center;

  a {
    color: ${(p) => p.theme.color.text.light};
    font-weight: ${(p) => p.theme.font.weight.semiBold};
    text-decoration: underline;
    cursor: pointer;
  }

  ${onDevice.mobile} {
    display: block;
    font-size: ${(p) => p.theme.font.size.s};
  }
`;

interface IParams {
  toRecoveryPage: () => void;
}

const Login: FC<IParams> = ({ toRecoveryPage }) => {
  const { actionUrl, irmaRequest, irmaServerBase, realm, error, registrationUrl, registrationUrlMobile, usernamePasswordLoginUrl, showAnotherWay } = useContext(AuthContext);

  const isError = error && error != '${error!}';
  const initState = {
    status: isError ? IrmaStatus.FAILED : IrmaStatus.READY,
    error: isError ? error : null,
  };

  const [irmaState, setIrmaState] = useState<IrmaState>(initState);
  const [goBack, setGoBack] = useState<boolean>(false);

  // TODO Improve error handling
  useEffect(() => {
    if (irmaState.status == IrmaStatus.FAILED) {
      switch (irmaState.error) {
        case IrmaError.ERROR_INVALID_IRMA_RESPONSE:
          alert(`Session has expired, please try again`);
          break;
        case IrmaError.ERROR_NO_IVIDO_IDENTIFIER:
          alert(`There is no Ivido identifier in the request, please create an account or retrieve a new attribute`);
          break;
        case IrmaError.ERROR_NO_USER:
          alert(`This Yivi attribute has no user account, please retrieve a new attribute`);
          break;
        case IrmaError.ERROR_MULTIPLE_USERS:
          alert(`Multiple user accounts were found for this user, please contact an Ivido administrator`);
          break;
      }
    }
  }, [irmaState]);

  let contentType: string;
  try {
    JSON.parse(irmaRequest);
    contentType = 'application/json';
  } catch (_e) {
    contentType = 'text/plain';
  }

  useEffect(() => {
    const irma = new IrmaCore({
      debugging: true, // Enable to get helpful output in the browser console
      element: '#irma-web-form', // Which DOM element to render to
      language: 'nl',
      no_auth: false,

      // Back-end options
      session: {
        // Point this to your IRMA server:
        url: irmaServerBase.replace(/\/$/, ''),

        start: {
          method: 'POST',
          headers: { 'Content-Type': contentType },
          body: irmaRequest,
        },

        result: {
          url: (o: { url: string }, s: { sessionToken: string }): string => `${o.url}/session/${s.sessionToken}/result-jwt`,
          parseResponse: (r: string) => r,
        },
      },
    });

    irma.use(Web);
    irma.use(Client);

    irma
      .start()
      .then((response: Response) => {
        console.log('Successful disclosure!');

        response.text().then((response) =>
          setIrmaState({
            status: IrmaStatus.FINISHED,
            response,
          })
        );
      })
      .catch((error: any) => {
        console.error("Couldn't do what you asked 😢", error);

        setIrmaState({
          status: IrmaStatus.FAILED,
          error,
        });
      });

    setIrmaState({ status: IrmaStatus.IN_PROGRESS });

    return function cleanup() {
      if (irma != null) {
        irma.abort();
      }
    };
  }, []);

  const registerRealm = realm === 'ivido' ? 'Ivido PGO' : 'HINQ ZNO';
  const loginProblemString = 'Problemen met inloggen?';

  return (
    <Container>
      {showAnotherWay === 'true' && usernamePasswordLoginUrl === '' && (
        <ButtonContainer>
          <Button buttonType={2} iconPosition={1} icon={faChevronLeft} onClick={() => setGoBack(true)}>
            Terug
          </Button>
        </ButtonContainer>
      )}
      {goBack && <SelfSubmittingForm actionUrl={actionUrl} values={{ tryAnotherWay: 'on' }} />}
      <StyledH1 mb={24}>Inloggen met de Yivi app</StyledH1>
      {irmaState.status == IrmaStatus.FINISHED && <SelfSubmittingForm actionUrl={actionUrl} values={{ irma_result: irmaState.response }} />}
      <Box>
        <ScanContainer>
          <QrContainer id="irma-web-form"></QrContainer>
          <img src={scan} />
        </ScanContainer>
        <Content>
          <H4 mb={16}>Stappen om in te loggen:</H4>
          <ol>
            <li>
              <span>1.</span>Open de Yivi app op je smartphone
            </li>
            <li>
              <span>2.</span>Druk op ‘’Scan QR Code’’
            </li>
            <li>
              <span>3.</span>Richt je smartphone op de QR code in het scherm
            </li>
            <li>
              <span>4.</span>Klik op 'Gegevens delen' in de Yivi app
            </li>
          </ol>
        </Content>
      </Box>
      {registrationUrl !== '' && (
        <Text>
          Nog geen {registerRealm} account? <a href={registrationUrl}>Registreer</a>
          <Fragment>
            <br />
            {loginProblemString}{' '}
            <a onClick={toRecoveryPage} href="#">
              Inlogcode opnieuw ophalen
            </a>
          </Fragment>
        </Text>
      )}
      {registrationUrlMobile !== '' && (
        <TextMobile>
          Nog geen {registerRealm} account? <a href={registrationUrlMobile}>Registreer</a>
          {realm === 'ivido' && (
            <Fragment>
              <br />
              {loginProblemString}{' '}
              <a onClick={toRecoveryPage} href="#">
                Inlogcode opnieuw ophalen
              </a>
            </Fragment>
          )}
        </TextMobile>
      )}
    </Container>
  );
};

export default Login;
